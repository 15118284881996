import { baseVipStore } from '@/store/vip'
import { t } from '@lingui/macro'
import { exchangeAndRaOpenStatusEnum, getVipTierHeaderProductLine, getVipTierProductLine } from '@/constants/vip'
import { baseUserStore } from '@/store/user'
import { formatNumberDecimal } from '../decimal'

export function getVipCdValue(codeVal: string) {
  const { codeDictMap } = baseVipStore.getState()
  return getVipTierProductLine()?.[codeVal] || codeDictMap?.[codeVal]
}

export function getVipHeaderValue(codeVal: string) {
  return getVipTierHeaderProductLine()?.[codeVal]
}

export function getDiscountAmt(discount: number | string, hasDecimal?: boolean) {
  const discountVal = hasDecimal ? formatNumberDecimal(discount) : discount

  return `${discountVal || 0}%`
}

export async function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

/**
 * 交易所和娱乐区开启状态
 */
export const getAppContentType = () => {
  const { userInfo } = baseUserStore.getState()
  const { exchange, ra } = userInfo || {}
  /** 是否开启交易所 */
  const hasExchange = exchange === exchangeAndRaOpenStatusEnum.open
  /** 是否开启娱乐区 */
  const hasRa = ra === exchangeAndRaOpenStatusEnum.open

  return { hasExchange, hasRa }
}

/**
 * 获取vip升级条件提示
 */
export const getUpgradeConditionTip = () => {
  const { hasExchange, hasRa } = getAppContentType()
  switch (true) {
    case hasExchange && hasRa:
      return t`upgrade_conditions_all`
    case hasExchange:
      return t`upgrade_conditions_exchange`
    case hasRa:
      return t`upgrade_conditions_ra`
    default:
      return t`upgrade_conditions_exchange`
  }
}

/**
 * 获取vip费率结算说明
 * @desc 这里需要依赖更新，所以外部传参
 */
export const getVipFeeCalculateTipData = (hasExchange, hasRa) => {
  switch (true) {
    case hasExchange && hasRa:
      return {
        tips: [
          t`features_vip_vip_tier_vip_tier_layout_index_rb0guesuwq`,
          t`features_vip_vip_tier_vip_tier_layout_index__d7lq8dwnk`,
          t`fee_instructions_1`,
          t`features_vip_vip_tier_vip_tier_layout_index_djibglq6nf`,
          t`features_vip_vip_tier_vip_tier_layout_index_ilth7hdz3b`,
        ],
        additionalTip: t`features_vip_vip_tier_vip_tier_layout_index_yqy1u13muj`,
      }
    case hasExchange:
      return {
        tips: [
          t`features_vip_vip_tier_vip_tier_layout_index_rb0guesuwq`,
          t`features_vip_vip_tier_vip_tier_layout_index__d7lq8dwnk`,
          t`features_vip_vip_tier_vip_tier_layout_index_djibglq6nf`,
          t`features_vip_vip_tier_vip_tier_layout_index_ilth7hdz3b`,
        ],
        additionalTip: t`features_vip_vip_tier_vip_tier_layout_index_yqy1u13muj`,
      }
    case hasRa:
      return {
        tips: [
          t`fee_instructions_1`,
          t`features_vip_vip_tier_vip_tier_layout_index_djibglq6nf`,
          t`features_vip_vip_tier_vip_tier_layout_index_ilth7hdz3b`,
        ],
      }
    default:
      return { tips: [] }
  }
}
